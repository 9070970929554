<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <form class="row ma-0">
          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.name"
              label="Name"
              dense
              outlined
              clearable
              :error-messages="nameErrors"
              @blur="$v.formData.name.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.email_credential_id"
              label="Email credentials"
              :items="serverData.email_credentials"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="email_credential_idErrors"
              @blur="$v.formData.email_credential_id.$touch()"
            />
          </div>

          <div class="col-12 h-85px">
            <Multiselect
              v-model="receivers"
              class="multi-select-filter-sidebar"
              tag-placeholder="Add this as new email"
              placeholder="Receivers"
              :options="options"
              label="name"
              track-by="code"
              openDirection="bottom"
              :multiple="true"
              :taggable="true"
              ref="selectRef"
              @tag="(query) => addTag(query)"
            >
              <template #clear>
                <div
                  class="multiselect__clear"
                  v-if="receivers.length"
                  @click.prevent.stop="resetReceivers"
                ></div>
              </template>
            </Multiselect>
            <!--            <v-text-field-->
            <!--              v-model="formData.receivers"-->
            <!--              label="Receivers"-->
            <!--              dense-->
            <!--              outlined-->
            <!--              clearable-->
            <!--              :error-messages="receiversErrors"-->
            <!--              @blur="$v.formData.receivers.$touch()"-->
            <!--            />-->
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.status"
              label="Status"
              :items="statusValues"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="statusErrors"
              @blur="$v.formData.status.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.report_id"
              label="Reports"
              :items="serverData.reports"
              item-text="text"
              item-value="index"
              return-object
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="report_idErrors"
              @blur="$v.formData.report_id.$touch()"
              @click:clear="() => $refs.filter.clearData()"
            />
          </div>

          <FilterSelector
            ref="filter"
            :removeSomeMargins="true"
            :filters="dynamicFiltersForSelectedReport"
            :submitFilterValues="applyFilters"
          />

          <div
            class="col-12 h-75px"
            :class="{ 'col-md-6': formData.schedule_type_id === 2 }"
          >
            <v-autocomplete
              v-model="formData.schedule_type_id"
              label="Schedule Type"
              :items="serverData.schedule_types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="scheduleTypeErrors"
              @blur="$v.formData.schedule_type_id.$touch()"
            />
          </div>

          <div
            v-if="formData.schedule_type_id === 2"
            class="col-12 col-md-6 h-75px"
          >
            <v-autocomplete
              v-model="formData.schedule_days"
              label="Schedule Days"
              :items="serverData.schedule_days"
              item-text="text"
              item-value="index"
              dense
              outlined
              multiple
              chips
              small-chips
              deletable-chips
              clearable
              hide-no-data
              hide-selected
              :error-messages="scheduleDaysErrors"
              @blur="$v.formData.schedule_days.$touch()"
            />
          </div>

          <div class="col-12 py-0 px-5">
            <v-radio-group
              label="Frequency"
              v-model="formData.frequency_type"
              row
              class="mt-0"
            >
              <v-radio label="Once" :value="1"></v-radio>
              <v-radio label="Interval" :value="2"></v-radio>
            </v-radio-group>
          </div>

          <div
            class="col-12 h-75px"
            :class="{ 'col-md-6': formData.frequency_type === 2 }"
          >
            <v-dialog
              ref="start_time_dialog"
              v-model="start_time_dialog"
              :return-value.sync="formData.schedule_start_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.schedule_start_time"
                  label="Start time"
                  append-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                  :error-messages="startTimeErrors"
                  @blur="$v.formData.schedule_start_time.$touch()"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="start_time_dialog"
                v-model="formData.schedule_start_time"
                full-width
                :max="formData.schedule_end_time"
                :use-seconds="false"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start_time_dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.start_time_dialog.save(formData.schedule_start_time)
                  "
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>

          <div
            class="col-12 col-md-6 h-75px"
            v-if="formData.frequency_type === 2"
          >
            <v-dialog
              ref="end_time_dialog"
              v-model="end_time_dialog"
              :return-value.sync="formData.schedule_end_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.schedule_end_time"
                  label="End time"
                  append-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="end_time_dialog"
                v-model="formData.schedule_end_time"
                full-width
                :min="formData.schedule_start_time"
                :use-seconds="false"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="end_time_dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.end_time_dialog.save(formData.schedule_end_time)
                  "
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>
          <div
            v-if="formData.frequency_type === 2"
            class="col-12 col-md-6 h-75px"
          >
            <v-text-field
              v-model="formData.frequency_hour"
              label="Frequency Hour"
              dense
              outlined
              type="number"
              :min="0"
              :max="frequencyHourLimit"
              clearable
              @change="hourLimitter"
              :error-messages="frequencyHourErrors"
              @blur="$v.formData.frequency_hour.$touch()"
            />
          </div>

          <div
            v-if="formData.frequency_type === 2"
            class="col-12 col-md-6 h-75px"
          >
            <v-text-field
              v-model="formData.frequency_minute"
              label="Frequency Minute"
              dense
              type="number"
              :min="0"
              :max="frequencyMinuteLimit"
              outlined
              clearable
              @change="minuteLimitter"
              :error-messages="frequencyMinuteErrors"
              @blur="$v.formData.frequency_minute.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.description"
              label="Description"
              dense
              outlined
              clearable
              hide-details
            />
          </div>
        </form>

        <!--begin::Actions-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { SET_DYNAMIC_FILTERS } from "@/core/services/store/emailReports.module";
// import MyFilter from "@/own/components/filter/MyFilter.vue";
import FilterSelector from "@/own/components/filter/FilterSelector.vue";
import {
  SET_FILTERS_DATA,
  SET_SKIP_ADDING_WAREHOUSE,
} from "@/core/services/store/filter.module";
import Multiselect from "vue-multiselect";

export default {
  name: "ActionsDialog",
  components: { Multiselect, FilterSelector },
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        // receivers: { required },
        report_id: { required },
        email_credential_id: { required },
        name: { required },
        status: { required },
        schedule_type_id: { required },
        frequency_hour: { required },
        frequency_minute: { required },
        schedule_start_time: { required },
        schedule_days: {
          required: requiredIf(() => {
            return this.formData.schedule_type_id === 2;
          }),
        },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    receivers: [],
    options: [],
    formData: {
      name: null,
      status: null,
      schedule_type_id: null,
      frequency_hour: 0,
      frequency_minute: 0,
      frequency_type: 1,
      description: null,
      schedule_days: [],
      schedule_start_time: null,
      schedule_end_time: null,
      report_id: null,
      email_credential_id: null,
      // receivers: null,
    },
    start_time_dialog: false,
    end_time_dialog: false,
    start_time: null,
    end_time: null,
  }),
  methods: {
    addTag(newTag) {
      newTag
        .trim()
        .replace(/\s\s+/g, " ")
        .split(" ")
        .forEach((element) => {
          this.options.push({
            name: element,
            code:
              element.substring(0, 2) + Math.floor(Math.random() * 10000000),
          });
          this.receivers.push({
            name: element,
            code:
              element.substring(0, 2) + Math.floor(Math.random() * 10000000),
          });
        });

      setTimeout(() => {
        this.$refs.selectRef.$el.focus();
      }, 100);
    },
    resetReceivers() {
      this.options = [];
      this.receivers = [];
    },
    hourLimitter() {
      if (this.formData.frequency_hour > 23) {
        this.formData.frequency_hour = 23;
      }
    },
    minuteLimitter() {
      if (this.formData.frequency_minute > 59) {
        this.formData.frequency_minute = 59;
      }
    },
    toggleModal() {
      this.$store.commit(SET_FILTERS_DATA, {});
      this.$store.commit(SET_SKIP_ADDING_WAREHOUSE, true);
      if (this.dialog) {
        this.resetFormData();
        this.serverData = null;
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        name: null,
        status: null,
        description: null,
        schedule_days: [],
        schedule_start_time: null,
        schedule_end_time: null,
        frequency_hour: 0,
        frequency_minute: 0,
        frequency_type: 1,
        report_id: null,
        email_credential_id: null,
        // receivers: null,
      };
    },
    loadDataFromServer() {
      this.pageLoader(true);
      this.resetReceivers();
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        ApiService.post("/settings/email_reports/edit", sendData)
          .then(({ data }) => {
            this.serverData = data;
            this.formData = JSON.parse(JSON.stringify(data.report_email));
            if (!("frequency_type" in this.formData)) {
              this.formData.frequency_type = 1;
            }

            this.formData.report_id = data.reports.find(
              (item) => item.index === data.report_email.report_id
            );

            const filters = {};
            data.report_email.report_filters.forEach((item) => {
              filters[item.name] = item.value;
            });

            this.$store.commit(SET_FILTERS_DATA, filters);

            data.report_email.receivers.forEach((element) => {
              this.options.push({
                name: element,
                code:
                  element.substring(0, 2) +
                  Math.floor(Math.random() * 10000000),
              });
              this.receivers.push({
                name: element,
                code:
                  element.substring(0, 2) +
                  Math.floor(Math.random() * 10000000),
              });
            });

            this.$store.commit(SET_DYNAMIC_FILTERS, data.dynamic_filters);
          })
          .finally(() => {
            this.pageLoader(false);
          });
      } else {
        ApiService.post("/settings/email_reports/create")
          .then(({ data }) => {
            this.serverData = data;
            this.$store.commit(SET_DYNAMIC_FILTERS, data.dynamic_filters);
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("minValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].minValue &&
          errors.push("Minimum value should be 10");
      }
      return errors;
    },
    applyFilters() {
      let val = this.$store.getters.getFiltersData;
      let report_filters = [];
      Object.entries(val).forEach((filter) => {
        if (
          filter[0] != null &&
          filter[1] != null &&
          filter[1] != undefined &&
          filter[1].length !== 0
        ) {
          report_filters.push({ name: filter[0], value: filter[1] });
        }
      });
      // console.log(report_filters);
      return report_filters;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.pageLoader(true);
      let sendData = {
        ...this.formData,
        report_id: this.formData.report_id.index,
        receivers: this.receivers?.map((item) => item.name),
        report_filters: this.applyFilters(),
      };

      if (this.actionType) {
        sendData.id = this.$store.getters.getItemForAction.id;
      }
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(`/settings/email_reports/${actionUrl}`, sendData)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    dynamicFilters: function() {
      return this.$store.getters.getSETTEMAILREPORTSDynamicFilters;
    },
    selectedReport: function() {
      if (this.formData?.report_id) return this.formData.report_id;
      else return null;
    },
    dynamicFiltersForSelectedReport: function() {
      let filters = [];
      if (this.selectedReport) {
        const moduleIndex = this.dynamicFilters.findIndex(
          (item) => item.module_id === this.selectedReport.module_id
        );
        if (moduleIndex > -1) {
          filters = this.dynamicFilters[moduleIndex].filters.filter((item) =>
            Boolean(
              this.selectedReport.filters.find((fil) => fil.name === item.name)
            )
          );
        }
      }
      return filters;
    },
    // if true, we are editing item, false we are adding
    actionType: function() {
      return !!this.$store.getters.getItemForAction;
    },
    frequencyMinuteLimit() {
      let validMinuteCount = 59;
      let scheduleStartTimeMinute = 0;
      if (this.formData.schedule_start_time != null) {
        scheduleStartTimeMinute = parseInt(
          this.formData.schedule_start_time.split(":")[1]
        );
      }
      let scheduleEndTimeMinute = 0;
      if (this.formData.schedule_end_time != null) {
        scheduleEndTimeMinute = parseInt(
          this.formData.schedule_end_time.split(":")[1]
        );
      }
      if (scheduleStartTimeMinute > scheduleEndTimeMinute) {
        validMinuteCount =
          parseInt(scheduleStartTimeMinute) - parseInt(scheduleEndTimeMinute);
      } else {
        validMinuteCount =
          60 +
          parseInt(scheduleStartTimeMinute) -
          parseInt(scheduleEndTimeMinute);
      }
      return validMinuteCount;
    },
    frequencyHourLimit() {
      let validHourCount = 23;
      let scheduleStartTimeHour = 0;
      let scheduleStartTimeMinute = 0;
      if (this.formData.schedule_start_time != null) {
        scheduleStartTimeHour = this.formData.schedule_start_time.split(":")[0];
        scheduleStartTimeMinute = this.formData.schedule_start_time.split(
          ":"
        )[1];
      }
      // let scheduleStartTime =
      //   ? this.formData.schedule_start_time.split(":")[0]
      //   : 0;
      let scheduleEndTimeHour = 23;
      let scheduleEndTimeMinute = 0;
      if (this.formData.schedule_end_time != null) {
        scheduleEndTimeHour = this.formData.schedule_end_time.split(":")[0];
        scheduleEndTimeMinute = this.formData.schedule_end_time.split(":")[1];
      }
      // let scheduleEndTimeHour = this.formData.schedule_end_time.split(":")[0]
      //   ? this.formData.schedule_end_time
      //   : 23;
      if (scheduleStartTimeHour > scheduleEndTimeHour) {
        validHourCount =
          24 -
          scheduleStartTimeHour +
          scheduleEndTimeHour -
          (scheduleEndTimeMinute > scheduleStartTimeMinute ? 1 : 0);
      } else {
        validHourCount =
          scheduleEndTimeHour -
          scheduleStartTimeHour -
          (scheduleEndTimeMinute > scheduleStartTimeMinute ? 1 : 0);
      }
      return validHourCount;
    },
    selectedWarehouse: function() {
      return this.$store.getters.getSelectedWarehouse;
    },
    submitButtonText: function() {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function() {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    statusValues: function() {
      return this.serverData.statuses || [];
    },
    nameErrors: function() {
      return this.handleFormValidation("name");
    },
    statusErrors: function() {
      return this.handleFormValidation("status");
    },
    report_idErrors: function() {
      return this.handleFormValidation("report_id");
    },
    // receiversErrors: function() {
    //   return this.handleFormValidation("receivers");
    // },
    email_credential_idErrors: function() {
      return this.handleFormValidation("email_credential_id");
    },
    frequencyHourErrors: function() {
      return this.handleFormValidation("frequency_hour");
    },
    frequencyMinuteErrors: function() {
      return this.handleFormValidation("frequency_minute");
    },
    scheduleTypeErrors: function() {
      return this.handleFormValidation("schedule_type_id");
    },
    scheduleDaysErrors: function() {
      return this.handleFormValidation("schedule_days");
    },
    startTimeErrors: function() {
      return this.handleFormValidation("schedule_start_time");
    },
    isDataLoaded() {
      return !!this.serverData;
    },
  },
};
</script>
